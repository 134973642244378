import type { TablePaginationRequest, TablePaginationResponse } from '~/types/GenericTable'
import type { BugReport, FeatureRequest, FeatureRequestPaginationRequest, ReportStatus } from '~/types/Support'
import { api } from '~/utils/backend'

export function paginateBugReports(request: TablePaginationRequest) {
  return api.post<TablePaginationResponse<BugReport>>('/api/v2/support/bug-reports/paginate', request)
}

export function deleteBugReport(id: string) {
  return api.delete<never>(`/api/v2/support/bug-reports/${id}`)
}

export function updateBugReportStatus(id: string, status: ReportStatus) {
  return api.put<never>(`/api/v2/support/bug-reports/${id}`, status)
}

export function paginateFeatureRequests(request: FeatureRequestPaginationRequest) {
  return api.post<TablePaginationResponse<FeatureRequest>>('/api/v2/support/feature-requests/paginate', request)
}

export function updateFeatureRequestStatus(id: string, status: ReportStatus) {
  return api.put<never>(`/api/v2/support/feature-requests/${id}`, status)
}

export function deleteFeatureRequest(id: string) {
  return api.delete<never>(`/api/v2/support/feature-requests/${id}`)
}

export function countItems() {
  return api.get('/api/v2/support/count')
}
